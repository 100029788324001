@font-face {
  font-family: 'FuturaHeavy';
  font-style: normal;
  src: url(/static/media/FuturaStd-Heavy.0fb1a46e.woff) format("woff"), url(/static/media/FuturaStd-Heavy.d68ac14c.woff2) format("woff2"), url(/static/media/FuturaStd-Heavy.e3861cc3.ttf) format("truetype"); }

@font-face {
  font-family: 'FuturaBold';
  font-style: normal;
  src: url(/static/media/FuturaStd-Bold.c1ec8dc4.otf); }

@font-face {
  font-family: 'FuturaBook';
  font-style: normal;
  src: url(/static/media/FuturaStd-Book.68f23ea5.woff) format("woff"), url(/static/media/FuturaStd-Book.0d399f23.woff2) format("woff2"), url(/static/media/FuturaStd-Book.12be3146.ttf) format("truetype"); }

@font-face {
  font-family: 'FuturaBookLight';
  src: url(/static/media/FuturaStd-Book-Light.d9a3da3f.ttf); }

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  src: url(/static/media/NunitoSans.ea597fcf.eot), url(/static/media/NunitoSans.07832512.woff) format("woff"), url(/static/media/NunitoSans.d425407e.woff2) format("woff2"), url(/static/media/NunitoSans.fb98ed17.ttf) format("truetype"); }

body {
  margin: 0;
  padding: 0;
  font-family: 'NunitoSans';
  background-image: linear-gradient(to bottom right, #fff, #e4e2ed);
  min-height: 100vh;
  height: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'FuturaHeavy'; }

a {
  text-decoration: none; }

.App {
  background: url(/static/media/dashboard_bgd.cae0d118.png) no-repeat center center fixed;
  background-size: cover;
  width: inherit;
  height: inherit;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100vh;
  width: 100vw; }

.App-mask {
  background-image: linear-gradient(201deg, #e84249 0%, #262957 76%);
  width: inherit;
  height: inherit; }

h1,
h2 {
  color: white;
  font-family: 'FuturaHeavy'; }

div[class^='MuiDialog-container'] h2 {
  color: #262957;
  font-family: 'FuturaHeavy'; }

.display-none {
  display: none; }

.button-bold span {
  font-weight: bold; }

.toast-style {
  font-size: 20px; }

.button-valid {
  font-family: 'FuturaBook' !important; }
  .button-valid:hover span {
    font-family: 'FuturaHeavy' !important; }

.button-cancel:hover {
  color: #5ac8f5 !important;
  background-color: rgba(255, 255, 255, 0) !important; }

.pastille-after:after {
  content: '\2022   ';
  color: #b30015; }

.pastilla {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5ac8f5;
  margin-left: 10px;
  border: 3px solid #d6efff; }

p,
span,
button {
  font-family: 'FuturaBook'; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
  margin-top: 15px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

#dialog-payment-types {
  text-align: center; }

.gestion-button-container button {
  width: 300px;
  border-color: white !important;
  transition: all 300ms; }
  .gestion-button-container button span {
    font-family: 'FuturaBook';
    color: white; }
  .gestion-button-container button:hover {
    background-color: white !important; }
    .gestion-button-container button:hover span {
      color: #e84249; }

.partner-option-container {
  color: #262957;
  min-width: 630px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 680px; }
  .partner-option-container .partner-option-subcontainer {
    background-color: white;
    border-radius: 5px;
    padding: 30px; }
  .partner-option-container .partner-option-empty {
    font-family: 'FuturaBook';
    text-align: center;
    font-style: italic; }

.option-container {
  display: flex;
  align-items: center; }
  .option-container .option-name {
    flex-grow: 5;
    font-size: 17px; }
  .option-container .option-name-disabled {
    opacity: 0.6; }
  .option-container .option-name-need_update {
    font-size: 17px;
    opacity: 0.6;
    font-weight: bold;
    font-style: italic; }

.option-container-child {
  padding-left: 60px;
  *border-left: 1px solid #292929; }

.option-child-arrow svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: relative;
  top: 6px;
  margin-right: 5px; }

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #80d2f3 !important; }

.option-settings-advanced {
  font-size: 15px;
  display: flex;
  align-items: center;
  transition: background-color 200ms;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer; }

.option-settings-advanced:hover {
  background-color: #26295710; }

.option-input-placeholder {
  width: 58px;
  padding: 0;
  padding-top: 2px; }

.option-category-label {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0; }

.option-event-name {
  font-style: italic;
  font-family: 'FuturaBook';
  font-size: 20px;
  margin-top: 30px; }

.partner-config-container {
  width: 680px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto; }

.partner-config-flex {
  margin-top: 10px;
  display: flex;
  justify-content: space-around; }
  .partner-config-flex a {
    display: flex;
    align-items: center;
    border: 1px solid white;
    font-size: 13px;
    border-radius: 20px;
    background-color: #262957;
    color: white; }
    .partner-config-flex a div {
      padding: 7px 25px 7px 25px;
      display: flex;
      align-items: center; }
  .partner-config-flex .outlined {
    color: white;
    background-color: transparent; }
    .partner-config-flex .outlined :hover {
      color: #262957 !important; }
  .partner-config-flex a:hover {
    background-color: white; }
    .partner-config-flex a:hover .outlined {
      background-color: white !important;
      color: #262957 !important; }

.card_action_help_text {
  border: 2px solid white;
  padding: 10px 30px;
  border-radius: 30px;
  transition: all 0.5s;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  margin: 0 auto; }
  .card_action_help_text:hover {
    background-color: white;
    color: #262957; }

.red-button {
  margin: 20px; }

.red-button button:disabled {
  background-color: rgba(232, 66, 73, 0.5) !important; }

.red-button button {
  background-color: #e84249;
  color: #fff !important;
  font-family: 'NunitoSans' !important;
  box-shadow: none;
  text-transform: none !important;
  font-size: 14px !important;
  padding: 5px 30px !important;
  border-radius: 40px; }
  .red-button button:hover {
    box-shadow: none;
    background-color: #ee5056 !important; }

.content-container .subHeader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 80px 0 80px; }
  @media screen and (max-width: 768px) {
    .content-container .subHeader-container {
      flex-direction: column;
      justify-content: center; } }
  .content-container .subHeader-container .left-container,
  .content-container .subHeader-container .right-container {
    flex: 1 1;
    display: flex;
    justify-content: start;
    text-align: start; }
  .content-container .subHeader-container .center-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%; }
    .content-container .subHeader-container .center-container h1 {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.content-container .partners-container {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%; }
  .content-container .partners-container .partners-subcontainer {
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.export-list {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .export-list .export-list-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    cursor: pointer; }

.add-export-container {
  width: 633px;
  height: 130px;
  border: 3px dashed rgba(232, 66, 73, 0.7);
  border-radius: 10px;
  cursor: pointer;
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.icon-add-export-card {
  position: relative;
  top: 10px;
  background-color: #e84249;
  width: 80px;
  height: 80px;
  border-radius: 40px; }
  .icon-add-export-card svg {
    font-size: 60px;
    margin-top: 10px;
    margin-left: 0px;
    color: white; }

.new-export-button-container {
  width: 100%;
  display: flex; }
  .new-export-button-container p {
    margin: 0 auto; }

.export-card-container {
  background-color: white;
  width: 590px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 1px #cecece;
  margin: 10px;
  color: #262957;
  align-items: center;
  display: flex;
  flex-direction: column; }
  .export-card-container .export-card-left {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%; }
    .export-card-container .export-card-left .export-card-active {
      font-size: 18px;
      color: #5ac8f5;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-left: auto; }
      .export-card-container .export-card-left .export-card-active p {
        margin: 0;
        width: 100px;
        text-align: right; }
      .export-card-container .export-card-left .export-card-active .pastilla {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5ac8f5;
        margin-left: 10px;
        border: 3px solid #d6efff; }
    .export-card-container .export-card-left h2 {
      font-family: 'FuturaBold';
      font-size: 25px;
      color: #262957; }
  .export-card-container .export-card-right {
    display: flex;
    width: 100%;
    margin-top: auto; }
    .export-card-container .export-card-right .export-card-date {
      font-size: 15px;
      color: gray;
      position: relative;
      bottom: -30px; }
    .export-card-container .export-card-right .export-card-actions {
      display: flex;
      margin-left: auto; }
      .export-card-container .export-card-right .export-card-actions svg {
        color: #e84249;
        cursor: pointer;
        font-size: 30px; }
      .export-card-container .export-card-right .export-card-actions button {
        font-size: 20px; }
        .export-card-container .export-card-right .export-card-actions button:hover {
          background-color: transparent; }
      .export-card-container .export-card-right .export-card-actions .secondary-button {
        font-size: 18px;
        text-decoration: underline; }
      .export-card-container .export-card-right .export-card-actions .red-button {
        margin: 0; }
        .export-card-container .export-card-right .export-card-actions .red-button button:disabled {
          background-color: #e2e2e2 !important; }
          .export-card-container .export-card-right .export-card-actions .red-button button:disabled span {
            color: #5d5d5d;
            font-family: 'FuturaBook'; }
      .export-card-container .export-card-right .export-card-actions .primary-button span {
        font-size: 16px;
        font-family: 'FuturaHeavy'; }

.sp {
  width: 16px;
  height: 16px;
  clear: both;
  margin: auto auto auto 10px; }

/* Spinner Circle Rotation */
.sp-circle {
  border: 4px rgba(180, 180, 180, 0.25) solid;
  border-top: 4px #b4b4b4 solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 1s infinite linear; }

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.export-name-calendar-container {
  /* background: #fff;
  box-shadow: 0px 1px 0px 1px #cecece;
  -moz-box-shadow: 0px 1px 0px 1px #cecece;
  -webkit-box-shadow: 0px 1px 0px 1px #cecece; */
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'futuraBook';
  margin-right: 15px; }
  .export-name-calendar-container .text-content {
    display: flex;
    align-items: center;
    color: #262957;
    width: 300px;
    text-align: left; }
    .export-name-calendar-container .text-content p,
    .export-name-calendar-container .text-content .exportName-textfield {
      margin: 0;
      font-family: 'futuraHeavy';
      font-size: 24px;
      width: 70%; }
    .export-name-calendar-container .text-content .export-modal-icon {
      margin-left: 10px;
      width: 24px;
      height: 24px;
      color: #e84249;
      cursor: pointer; }
    .export-name-calendar-container .text-content .exportName-textfield {
      margin: 0;
      padding: 0;
      width: 70%; }
      .export-name-calendar-container .text-content .exportName-textfield > div:before {
        border: 0;
        content: none; }
      .export-name-calendar-container .text-content .exportName-textfield > div:after {
        border: 0;
        content: none; }
      .export-name-calendar-container .text-content .exportName-textfield > div > input {
        color: #262957;
        font-family: 'futuraHeavy';
        font-size: 24px; }
    .export-name-calendar-container .text-content .DayPickerInput input {
      font: inherit;
      border: 0;
      margin: 0;
      padding: 0;
      color: #e84249;
      font-family: 'futuraBook';
      outline: 0;
      font-weight: 600; }
      .export-name-calendar-container .text-content .DayPickerInput input::-webkit-input-placeholder {
        color: #e84249;
        font-weight: 600; }
      .export-name-calendar-container .text-content .DayPickerInput input::placeholder {
        color: #e84249;
        font-weight: 600; }

.new-export-button-container {
  width: 100%;
  display: flex; }

.add-export-container {
  width: 633px;
  height: 150px;
  border: 3px dashed rgba(232, 66, 73, 0.7);
  border-radius: 10px;
  cursor: pointer;
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .add-export-container p {
    margin: 0 auto;
    color: #292929;
    font-size: 20px;
    display: flex;
    justify-content: center; }

.icon-add-export-card {
  position: relative;
  top: 10px;
  background-color: #e84249;
  width: 80px;
  height: 80px;
  border-radius: 40px; }
  .icon-add-export-card svg {
    font-size: 60px;
    margin-top: 10px;
    margin-left: 0px;
    color: white; }

.input-error-label {
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
  color: red;
  width: 175px; }

@charset "UTF-8";
.export-parent-container {
  position: relative; }
  .export-parent-container h2 {
    font-size: 22px; }
  .export-parent-container .exportModal-container {
    display: flex;
    align-items: center; }
  .export-parent-container .export-modal-container {
    min-height: 80vh;
    display: flex;
    flex-direction: row; }
    .export-parent-container .export-modal-container .recap-container {
      width: 20%;
      margin: 10px;
      z-index: 10;
      background-color: #fff; }
    .export-parent-container .export-modal-container .section-category {
      width: 20%;
      margin: 10px 10px 10px 0; }
    .export-parent-container .export-modal-container .section-products {
      width: 60%;
      margin: 10px; }
  .export-parent-container .valid-button-fixed {
    position: absolute !important;
    bottom: 20px;
    right: 20px;
    font-family: 'futuraBold';
    letter-spacing: 0.4px;
    width: 120px; }
  .export-parent-container .close-export-modal-icon {
    margin-left: auto;
    width: 24px;
    height: 24px;
    cursor: pointer; }

.middle-content-container {
  background: #fafafa;
  height: 97%;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }
  .middle-content-container .form-product-container {
    padding: 20px 10px 0 10px; }
  .middle-content-container .section-label {
    font-family: 'FuturaBook';
    color: #262957;
    margin: 0; }
  .middle-content-container #product-label-with-tva {
    line-height: 25px;
    font-weight: 700;
    *text-align: center; }
    .middle-content-container #product-label-with-tva span {
      font-weight: initial; }
  .middle-content-container .section-label-red {
    color: #e84249; }
  .middle-content-container .no-section-selected {
    text-align: center;
    font-size: 20px;
    padding-top: 20px; }
  .middle-content-container .list-without-margin li {
    cursor: pointer; }

.product-empty {
  text-align: center;
  color: #ababab;
  font-size: 2em; }

.section-list-container ul {
  padding: 0; }

.section-list-container .section-list {
  background: #f6f6f6;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2); }
  .section-list-container .section-list .menu-icon {
    color: #e84249; }

.section-list-container .new-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
  font-family: 'FuturaBook';
  font-size: 15px;
  color: #e84249; }
  .section-list-container .new-section-container p {
    margin-right: 5px; }
  .section-list-container .new-section-container svg {
    font-size: 20px; }

.list-item,
.product-item {
  z-index: 9999999;
  align-items: center;
  font-family: 'FuturaBook';
  list-style-type: none; }

.section-item-container {
  width: 100%;
  color: #fff; }
  .section-item-container > div:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
    .section-item-container > div:first-child:hover {
      background-color: rgba(90, 200, 245, 0.2); }
  .section-item-container > div > div {
    padding: 0 !important;
    color: #fff !important;
    background-color: none !important; }
    .section-item-container > div > div span {
      font-family: 'FuturaBook';
      font-size: 18px;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inherit; }
  .section-item-container .list-divider {
    background-color: #fff; }
  .section-item-container .product-list-container ul {
    padding: 0;
    margin: 0; }

.list-item .product-item > li > div {
  padding-left: 40px; }
  .list-item .product-item > li > div:hover {
    background-color: rgba(90, 200, 245, 0.1); }

.list-item .product-item > li > div > div {
  padding: 0 !important;
  color: #e84249; }
  .list-item .product-item > li > div > div span {
    color: #262957;
    font-family: 'FuturaBook';
    font-size: 15px; }
    .list-item .product-item > li > div > div span .menu-product-icon {
      width: 18px;
      height: 18px;
      color: #e84249; }

.list-item .product-item .close-icon-container {
  width: 18px;
  margin-right: 5px; }
  .list-item .product-item .close-icon-container:hover {
    cursor: pointer;
    background-color: transparent; }

.list-item .list-divider {
  background-color: #fff; }

.white span {
  color: #fff !important; }
  .white span svg {
    color: #fff !important; }

.blue {
  color: #262957; }
  .blue span {
    color: #262957 !important; }

.product-selected {
  background-color: rgba(90, 200, 245, 0.2) !important; }
  .product-selected span {
    font-family: 'FuturaHeavy' !important; }

.recap-export-container {
  font-family: 'FuturaBook';
  color: #262957; }
  .recap-export-container .section-title {
    font-family: 'FuturaHeavy'; }
  .recap-export-container li {
    list-style-type: none;
    line-height: 25px; }
    .recap-export-container li p {
      margin: 0;
      font-style: italic; }
  .recap-export-container .empty-item {
    display: flex;
    align-items: center; }
    .recap-export-container .empty-item::before {
      content: '\2022   ';
      color: #b30015;
      margin-left: -14px;
      margin-right: 5px; }
    .recap-export-container .empty-item p {
      color: #b30015; }
  .recap-export-container .disabled-item {
    text-decoration: line-through; }
  .recap-export-container .empty-section {
    font-style: italic; }
  .recap-export-container .incomplete-string {
    color: #b30015;
    font-style: italic;
    text-align: center; }

.form-section-container .section-header-container {
  font-family: 'futuraBook';
  display: flex; }
  .form-section-container .section-header-container .input-name-container .section-label {
    color: #262957;
    margin: 0;
    padding: 20px 0 10px 10px; }
  .form-section-container .section-header-container .input-name-container .input-name {
    margin-left: 10px;
    display: flex;
    align-items: center;
    background: #fff;
    color: #262957; }
    .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield {
      margin: 0;
      padding: 0; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div:before {
        border: 0;
        content: none; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div:after {
        border: 0;
        content: none; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div > input {
        margin-bottom: 10px;
        padding: 0 5px;
        color: #ababab;
        font-family: 'futuraBook'; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield > div > textarea {
        padding: 5px; }
      .form-section-container .section-header-container .input-name-container .input-name .sectionName-textfield :focus {
        outline: -webkit-focus-ring-color auto 5px;
        color: #292929; }
    .form-section-container .section-header-container .input-name-container .input-name .save-section-icon {
      color: #e84249;
      padding: 3px;
      cursor: pointer; }
    .form-section-container .section-header-container .input-name-container .input-name .save-section-icon-disabled {
      opacity: 0.3; }
  .form-section-container .section-header-container .input-name-container .input-description {
    width: 250px; }
  .form-section-container .section-header-container .delete-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    padding-right: 10px;
    color: #e84249;
    cursor: pointer; }
    .form-section-container .section-header-container .delete-section-container .section-label {
      margin: 0;
      padding: 20px 10px 10px 0; }
    .form-section-container .section-header-container .delete-section-container .delete-section-icon {
      font-size: 30px; }

.form-section-container .product-selector-container {
  text-align: right; }
  .form-section-container .product-selector-container > div {
    padding: 30px 0 15px 0; }

.form-section-container ul {
  padding: 10px;
  margin: 0;
  overflow: auto;
  *height: 67vh; }
  .form-section-container ul li {
    list-style: none; }
    .form-section-container ul li hr {
      border-color: rgba(247, 247, 247, 0.3); }
    .form-section-container ul li .product-item-container {
      display: flex;
      align-items: center;
      color: #262957;
      padding: 0 10px; }
      .form-section-container ul li .product-item-container .product-item-close-icon {
        margin-left: auto;
        color: #e84249;
        width: 18px;
        cursor: pointer; }

.products-selector-modal-container {
  display: flex;
  flex-direction: row;
  height: 84%; }
  .products-selector-modal-container .section-category {
    width: 20%;
    height: 100%;
    padding: 0; }
    .products-selector-modal-container .section-category h2 {
      margin: 0;
      height: 10%;
      display: flex;
      align-items: center; }
    .products-selector-modal-container .section-category .products-category-container {
      height: 90%;
      padding: 0; }
      .products-selector-modal-container .section-category .products-category-container span {
        color: #262957; }
  .products-selector-modal-container .section-products {
    width: 60%;
    height: 100%;
    margin: 0 20px; }
    .products-selector-modal-container .section-products .section-products-header {
      margin: 0;
      height: 10%; }
      .products-selector-modal-container .section-products .section-products-header h2 {
        margin: 0; }
    .products-selector-modal-container .section-products .section-product-body {
      height: 90%; }
      .products-selector-modal-container .section-products .section-product-body #select-all-container {
        height: 13%;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .products-selector-modal-container .section-products .section-product-body #select-all-container #placeholder-select-all {
          float: right;
          margin: 35px 35px 0; }
        .products-selector-modal-container .section-products .section-product-body #select-all-container #select-all {
          margin-right: 4px;
          color: #262957; }
      .products-selector-modal-container .section-products .section-product-body .productsSelector-products-list {
        padding: 0;
        height: calc(87% - 1px); }
  .products-selector-modal-container .section-recap {
    width: 20%;
    height: 100%; }
    .products-selector-modal-container .section-recap h2 {
      margin: 0;
      height: 10%;
      display: flex;
      align-items: center; }
    .products-selector-modal-container .section-recap .section-selected-container {
      width: auto;
      height: 90%;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2); }
      .products-selector-modal-container .section-recap .section-selected-container .section-selected {
        overflow-y: auto;
        height: 88%;
        margin-bottom: 50px;
        color: #262957; }
      .products-selector-modal-container .section-recap .section-selected-container .item-selected-menu {
        font-size: 20px;
        font-family: 'FuturaBook';
        margin-left: 10px; }
      .products-selector-modal-container .section-recap .section-selected-container .chip-item {
        margin: 8px;
        width: calc(100% - 25px);
        font-family: FuturaBook;
        border-radius: 4px;
        background-color: #e2f5fd;
        padding: 10px 0px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .products-selector-modal-container .section-recap .section-selected-container .chip-item .chip-name {
          flex-grow: 15;
          vertical-align: bottom;
          line-height: 30px; }
        .products-selector-modal-container .section-recap .section-selected-container .chip-item .chip-delete {
          color: red;
          flex-grow: 1;
          cursor: pointer;
          display: flex;
          align-items: center; }

.selector-modal-content {
  background-image: linear-gradient(#f9f8fb, #ebe9f1);
  font-family: 'FuturaBook';
  padding: 0 24px !important;
  overflow: hidden !important;
  height: 100%; }
  .selector-modal-content h1 {
    height: 8%;
    margin: 0;
    display: flex;
    align-items: center; }
  .selector-modal-content h2 {
    color: #2b2e62; }
  .selector-modal-content .menu-and-name-container {
    display: flex;
    align-items: center;
    height: 8%; }

.section-products .section-products-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.section-products .section-product-body {
  background-color: white;
  height: 100%; }
  .section-products .section-product-body .section-product-body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px; }
    .section-products .section-product-body .section-product-body-header #search-container {
      position: relative;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      margin-left: 20px;
      box-shadow: 0px 0px 10px 1px #cecece;
      border-radius: 4px; }
      .section-products .section-product-body .section-product-body-header #search-container #placeholder-search {
        width: 360px;
        height: 43px; }
      .section-products .section-product-body .section-product-body-header #search-container #standard-search {
        background-color: white;
        border: none;
        border-radius: 8px;
        padding: 5px 40px 5px 20px;
        height: 30px;
        width: 300px; }
      .section-products .section-product-body .section-product-body-header #search-container #standard-search::-webkit-input-placeholder {
        font-style: italic;
        font-size: 15px;
        opacity: 0.5;
        font-family: 'FuturaBook'; }
      .section-products .section-product-body .section-product-body-header #search-container #standard-search::placeholder {
        font-style: italic;
        font-size: 15px;
        opacity: 0.5;
        font-family: 'FuturaBook'; }
      .section-products .section-product-body .section-product-body-header #search-container img {
        position: absolute;
        width: 25px;
        right: 10px; }
  .section-products .section-product-body .item-selectable-parent li {
    height: 100%; }
    .section-products .section-product-body .item-selectable-parent li .item-selectable {
      height: 100%; }
      .section-products .section-product-body .item-selectable-parent li .item-selectable .item-selectable-container {
        display: flex;
        justify-content: space-between;
        color: #262957; }
        .section-products .section-product-body .item-selectable-parent li .item-selectable .item-selectable-container span {
          margin-right: 20px; }

.section-products #item-selectable-empty {
  height: 520px;
  padding: 50px;
  text-align: center; }
  .section-products #item-selectable-empty p {
    font-size: 1.5em;
    text-transform: uppercase;
    opacity: 0.4;
    vertical-align: center; }

#item-selectable-content-placeholder {
  height: 544px;
  width: 100%; }

#products-selector-modal-action {
  background-color: #f7f7f7;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: initial;
  height: 50px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-around; }
  #products-selector-modal-action button {
    font-size: 20px; }
    #products-selector-modal-action button span {
      font-family: 'FuturaBook'; }
  #products-selector-modal-action #products-selector-modal-valid {
    width: 150px; }

.menu-selector {
  padding: 1% 0;
  width: 270px;
  border-radius: 10px;
  margin-right: 20px; }

.menu-selector-disabled {
  opacity: 0.4;
  cursor: not-allowed; }

.section-empty {
  text-align: center;
  font-size: 1em;
  text-transform: uppercase;
  opacity: 0.4;
  vertical-align: center; }

.selectable-products-list {
  height: 100% !important; }

@media (min-width: 1600px) {
  .menu-selector {
    height: 1%; }
  .products-selector-modal-container
.section-products
.section-product-body
#select-all-container {
    height: 10%; }
  .products-selector-modal-container
.section-products
.section-product-body
.productsSelector-products-list {
    height: calc(90% - 1px); } }

.form-product-save {
  text-align: right;
  margin-top: 20px; }

.product-vat {
  font-style: italic;
  opacity: 0.6; }

.form-product-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .form-product-container-header .delete-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    color: #e84249;
    cursor: pointer; }
    .form-product-container-header .delete-section-container .section-label {
      margin: 0;
      padding-bottom: 10px; }
    .form-product-container-header .delete-section-container .delete-section-icon {
      font-size: 30px; }

.form-category-supplement-info {
  display: flex;
  align-items: center; }
  .form-category-supplement-info .form-category-supplement-title {
    color: #262957;
    font-family: 'FuturaHeavy';
    *margin-right: 10px; }
  .form-category-supplement-info .form-category-supplement-title-disabled {
    color: #bebebe; }
  .form-category-supplement-info .form-category-supplement-type {
    color: #dfaa2f;
    font-family: 'FuturaBook';
    font-style: italic;
    margin: 0 10px !important; }
  .form-category-supplement-info .form-category-supplement-type-config {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-family: 'FuturaBook'; }
    .form-category-supplement-info .form-category-supplement-type-config > div {
      margin-right: 10px; }
  .form-category-supplement-info .drag-category-icon {
    width: 24px;
    height: 24px; }
  .form-category-supplement-info .form-category-supplement-container {
    display: flex;
    align-items: center; }
    .form-category-supplement-info .form-category-supplement-container p {
      margin: 0; }
  .form-category-supplement-info .form-category-supplement-type-config p {
    margin: 0; }
  .form-category-supplement-info .menu-icon {
    fill: #e84249; }

.type-config-hidden {
  display: none !important; }

.form-supplement-item {
  border-bottom: 1px solid #ebebeb !important;
  height: 40px;
  padding: 0 !important; }
  .form-supplement-item span {
    font-family: 'FuturaBook';
    color: #262957; }

.form-supplement-item-disabled span {
  color: #bebebe; }

.supplement-button-panel {
  background: #fff;
  border: 1px solid #e7e7e7;
  color: #4e708d;
  font-family: 'FuturaBook';
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px; }
  .supplement-button-panel p {
    margin: 0; }
  .supplement-button-panel svg {
    margin-left: auto;
    color: #e84249; }

.block {
  opacity: 1 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important; }

.hidden {
  opacity: 0;
  width: 0;
  height: 0; }

.supplement-modal-container {
  background: #fafafa;
  position: absolute;
  top: 0;
  left: 100%;
  transition: all 0.5s ease;
  z-index: 9; }

.supplement-modal-container-product-in-menu {
  background-color: #f0f0f0;
  height: 400px !important;
  overflow: scroll; }

.supplement-modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.remove-extras-button {
  transition: background-color 200ms;
  border: solid 1px #262957;
  border-radius: 5px;
  *cursor: pointer;
  height: 35px;
  margin-top: 10px; }

.back-button {
  display: flex;
  align-items: center;
  font-family: 'FuturaBook';
  border-radius: 5px;
  color: #e84249;
  margin: 10px;
  width: 10%;
  cursor: pointer; }
  .back-button:hover {
    color: #5ac8f5; }
  .back-button:hover svg {
    color: #5ac8f5; }

.save-button button {
  margin: 10px;
  font-family: 'FuturaBook'; }
  .save-button button :hover {
    font-family: 'FuturaHeavy'; }

.display-flex div {
  display: flex;
  align-items: center; }

.menu-product-icon {
  fill: #e84249 !important; }

.category-list-container {
  padding: 0px 20px; }
  .category-list-container .list-item-category {
    /* -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2); */
    cursor: pointer;
    /* margin-bottom: 5px;
    .supplement-active-checkbox {
      padding: 10px;
    } */ }

.supplement-modal-product-name {
  font-style: italic;
  padding-left: 10px; }

body {
  margin: 0;
  padding: 0; }

.edit-modal {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 25%;
  height: 60%;
  width: 50%;
  min-height: 685px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 920px) {
    .edit-modal {
      left: 0;
      width: 100%;
      min-height: 550px; } }

.edit-modal-small {
  min-height: 600px;
  height: 44%; }

.edit-modal-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 50%; }

.edit-popup {
  position: fixed;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.6);
  /* Fallback color */
  z-index: 11; }

.crop-container {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto; }
  .crop-container div:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }

.cropper {
  height: 45%;
  margin-bottom: 50px; }
  .cropper .controls {
    position: relative;
    top: -40px; }

.add-picture {
  position: relative;
  width: 92%;
  height: 400px;
  margin: 0;
  display: flex;
  padding: 4%; }
  .add-picture .add-picture-container {
    width: 92%;
    height: 92%;
    border: 2px dashed #ababab;
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #262957;
    font-family: 'FuturaBook'; }
    .add-picture .add-picture-container .picto-pola {
      width: 60px; }
    .add-picture .add-picture-container .gliss-text {
      text-align: center;
      width: 130px;
      color: #e84249; }
    .add-picture .add-picture-container .input-file {
      display: none; }
    .add-picture .add-picture-container .withBackground {
      font-family: 'FuturaBook';
      padding: 0 50px;
      margin-top: 14px; }
      .add-picture .add-picture-container .withBackground:hover {
        background: #e84249;
        font-family: 'FuturaHeavy'; }
    .add-picture .add-picture-container .dropzone {
      outline: 0;
      position: absolute;
      width: 93%;
      height: 93%; }

.controls {
  box-sizing: border-box;
  position: relative;
  width: 40%;
  margin: 0 auto;
  padding: 0 16px;
  height: 80px;
  display: flex;
  align-items: center; }
  .controls .small-size {
    width: 20px;
    margin-right: 10px; }
  .controls .big-size {
    width: 30px;
    margin-left: 10px; }

.input-container {
  display: flex;
  flex-direction: column;
  padding: 0 10% 4% 10%;
  height: 24%; }
  .input-container label {
    font-size: 20px;
    font-family: 'FuturaBook';
    color: #7e8198; }
  .input-container div {
    margin: 0; }

.button-container {
  align-self: flex-end;
  margin: 0 20px 20px 0; }
  .button-container .withoutBackground {
    color: #262957;
    font-family: 'FuturaBook';
    padding: 0 25px; }
    .button-container .withoutBackground:hover {
      background: none;
      color: #5ac8f5; }
  .button-container .withBackground {
    font-family: 'FuturaBook';
    padding: 0 25px; }
    .button-container .withBackground:hover {
      background: #e84249;
      font-family: 'FuturaHeavy'; }

@media (min-width: 920px) {
  .galery-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4%; } }

@media (max-width: 920px) {
  .galery-container {
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
    height: inherit; } }

.galery-container .galery-item {
  margin: 0 15px;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  cursor: pointer; }
  @media (max-width: 920px) {
    .galery-container .galery-item {
      width: 60px;
      height: 60px;
      margin: 0 10px;
      display: inline-block;
      vertical-align: middle; } }

.galery-container .galery-link-to {
  border: 1px solid #777; }
  @media (min-width: 920px) {
    .galery-container .galery-link-to {
      display: flex;
      align-items: center;
      justify-content: center; } }

.galery-container .item-disabled {
  opacity: 0.5;
  cursor: initial; }

.gallery-item {
  width: 100px;
  height: 100px;
  border: 1px solid #aaaaaa;
  margin: 10px;
  cursor: pointer; }

.item-disabled {
  opacity: 0.5;
  cursor: initial; }

.form-input-image-card {
  position: relative;
  border-radius: 10px;
  width: 250px;
  max-height: 400px; }
  .form-input-image-card img {
    width: 100%;
    height: auto;
    border-radius: 10px; }
  .form-input-image-card button {
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    color: #e84249;
    top: 5px;
    right: 5px;
    background-color: white;
    cursor: pointer;
    transition: all 500ms; }
  .form-input-image-card button:hover {
    background-color: #e84249;
    color: white; }

.display-block {
  display: block; }

.modal {
  position: fixed;
  /* Stay in place */
  z-index: 11;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */ }

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px; }

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#modal-caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #f6f6f6;
  padding: 10px 0; }

/* Add Animation - Zoom in the Modal */
.modal-content,
#modal-caption {
  -webkit-animation-name: zoom;
          animation-name: zoom;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s; }

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoom {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* The Close Button */
.modal-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s; }

.modal-close:hover,
.modal-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer; }

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%; } }

.form-product-title {
  color: #2b2e62;
  font-family: 'FuturaHeavy';
  margin-bottom: 7px; }

.form-product-input,
.form-product-description {
  margin-bottom: 20px; }
  .form-product-input input,
  .form-product-description input {
    width: 150px; }
    .form-product-input input[type='text'],
    .form-product-description input[type='text'] {
      width: 250px; }
  .form-product-input textarea,
  .form-product-description textarea {
    width: 300px;
    height: 80px;
    min-height: 50px;
    max-height: 400px; }
  .form-product-input input,
  .form-product-input textarea,
  .form-product-description input,
  .form-product-description textarea {
    resize: vertical;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    border: 1px solid #e7e7e7;
    color: #ababab;
    font-family: 'FuturaBook';
    font-size: 16px; }
    .form-product-input input:focus,
    .form-product-input textarea:focus,
    .form-product-description input:focus,
    .form-product-description textarea:focus {
      color: #2b2e62; }

.form-product-switch {
  display: flex;
  align-items: center; }
  .form-product-switch form-product-switch-name {
    width: 300px; }

.form-product-visibility {
  display: flex;
  align-items: center; }
  .form-product-visibility svg {
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer; }

.form-product-visibility-disabled {
  cursor: not-allowed; }
  .form-product-visibility-disabled .form-product-title {
    opacity: 0.3; }
  .form-product-visibility-disabled svg {
    opacity: 0.3;
    cursor: not-allowed; }

.form-product-visibility-off {
  color: #ababab; }

.form-product-image-import {
  background-color: #fff;
  color: #e84249;
  border: none;
  border-radius: 7px;
  font-family: 'FuturaHeavy';
  font-size: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 7px 30px 5px 30px;
  cursor: pointer; }

.under-level-title-container {
  vertical-align: center; }
  .under-level-title-container span {
    color: #262957 !important; }
  .under-level-title-container svg {
    fill: #262957; }
  .under-level-title-container .menu-icon {
    fill: #e84249; }
  .under-level-title-container li {
    cursor: pointer; }
  .under-level-title-container .expandless-icon {
    fill: #e84249; }

.menu-level-item span {
  color: #262957 !important; }

.menu-level-item svg {
  fill: #262957; }

.menu-level-item .menu-icon {
  fill: #e84249; }

.under-level-hub-checkbox {
  display: flex;
  align-items: center; }

.list-item-container span div {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.under-level-title span,
.under-level-title input {
  color: #262957;
  font-family: 'futuraHeavy' !important; }

.under-level-container {
  margin: 0; }

.list-item-product-disabled {
  opacity: 0.5; }

.modalExpensive {
  width: 30% !important;
  min-width: 750px;
  transition: all 1s ease; }

.modalExpensiveFull {
  width: 100% !important; }

.expensive-container {
  padding: 24px 24px 0 24px;
  flex: 1 1 auto; }

.export-see-more {
  color: white;
  text-decoration: underline;
  font-family: 'FuturaBook';
  font-size: 15px;
  text-align: right;
  cursor: pointer;
  *margin: 0px 15px 20px 0px; }

.export-see-more:hover {
  opacity: 0.75; }

.export-loading {
  text-align: center;
  font-family: 'FuturaBook';
  font-size: 2em;
  color: #262957;
  -webkit-animation: color-change 2s infinite;
          animation: color-change 2s infinite;
  padding-bottom: 24px; }

@-webkit-keyframes color-change {
  0% {
    color: #262957; }
  50% {
    color: #fefefe; }
  100% {
    color: #262957; } }

@keyframes color-change {
  0% {
    color: #262957; }
  50% {
    color: #fefefe; }
  100% {
    color: #262957; } }

.export-container-menu {
  display: flex;
  flex-direction: column;
  align-items: center; }

.export-container-menu-configuration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
  width: 680px;
  align-items: center; }

.export-title {
  font-family: 'FuturaBookLight';
  font-weight: lighter;
  font-size: 25px;
  text-align: left !important;
  color: white; }

.m-alert {
  display: flex;
  padding: 10px 30px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: transparent;
  width: 500px;
  align-items: center;
  margin-right: auto;
  margin-left: auto; }

.m-alert-icon {
  margin-right: 15px; }

.m-alert-warning {
  color: #f6950e;
  background-color: #f9e1bd; }

.m-alert-success {
  color: #4aac3b;
  background-color: #d4ffd6; }

.m-alert-error {
  color: #f85549;
  background-color: #f5aba5; }

.m-alert-info {
  color: #00509b;
  background-color: #d6ebff; }

.category-supplement-container {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  min-width: 500px; }
  .category-supplement-container svg {
    color: #262957; }
  .category-supplement-container .category-supplement-info {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .category-supplement-container .category-supplement-info .name {
      color: #262957;
      font-family: 'FuturaHeavy'; }
  .category-supplement-container .label-supplement {
    display: flex;
    border-bottom: 1px solid #262957;
    color: #262957;
    opacity: 0.6;
    margin: -5px 0 2px; }
    .category-supplement-container .label-supplement span:first-child {
      width: 45%;
      text-align: center; }
    .category-supplement-container .label-supplement span:nth-child(2) {
      width: 30%;
      text-align: center; }
    .category-supplement-container .label-supplement span:nth-child(3) {
      width: 25%;
      text-align: center; }

.input-category-supplement {
  display: flex;
  align-items: center;
  flex-grow: 5;
  justify-content: space-between; }

.supplement-container {
  margin-left: 30px; }
  .supplement-container .supplement-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px; }
    .supplement-container .supplement-info .name {
      color: #262957; }
    .supplement-container .supplement-info .check-name-supplement {
      display: flex;
      align-items: center;
      width: 45%;
      text-align: center; }
    .supplement-container .supplement-info .supplement-image-container {
      width: 30%;
      text-align: center;
      position: relative; }
      .supplement-container .supplement-info .supplement-image-container button {
        width: 80px;
        height: 80px;
        border: 3px dashed rgba(232, 66, 73, 0.4);
        outline: 0;
        background-color: white; }
        .supplement-container .supplement-info .supplement-image-container button .icon-add-export {
          width: 20px;
          height: 20px; }
          .supplement-container .supplement-info .supplement-image-container button .icon-add-export svg {
            font-size: 15px;
            cursor: pointer; }
      .supplement-container .supplement-info .supplement-image-container .supplement-image-overlay {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease;
        background-color: rgba(255, 255, 255, 0.4); }
        .supplement-container .supplement-info .supplement-image-container .supplement-image-overlay svg {
          cursor: pointer;
          color: #e84249; }
      .supplement-container .supplement-info .supplement-image-container:hover .supplement-image-overlay {
        opacity: 1; }
    .supplement-container .supplement-info .supp-price {
      width: 25%;
      text-align: center; }

.supplement-reset-button {
  margin-left: auto; }
  .supplement-reset-button svg {
    color: #e84249; }

.input-supplement {
  padding: 5px 10px 5px 10px;
  margin-right: 20px;
  height: 27px;
  border-radius: 10px;
  display: flex;
  align-items: center; }

.dialog-content-scroll {
  overflow-y: inherit !important; }

.gestion-button-supplement {
  width: 300px;
  border-color: #e84249 !important;
  transition: all 300ms; }
  .gestion-button-supplement span {
    font-family: 'FuturaBook';
    color: #e84249; }
  .gestion-button-supplement:hover {
    background-color: #e84249 !important; }
    .gestion-button-supplement:hover span {
      color: white; }

.supplement-image-container {
  width: 30%; }
  .supplement-image-container .supplement-image {
    width: 80px;
    height: 80px; }

.button-payplus-container {
  width: 300px;
  display: flex;
  justify-content: center; }
  .button-payplus-container button {
    width: 250px;
    background-color: #e84249;
    color: #fff !important;
    box-shadow: none;
    border: none;
    font-size: 15px !important;
    padding: 9px 30px !important;
    border-radius: 5px;
    font-family: 'FuturaHeavy';
    cursor: pointer;
    transition: all 500ms; }
  .button-payplus-container button:hover {
    opacity: 0.6; }
  .button-payplus-container button.button-playplus-disabled {
    cursor: default;
    transition: none;
    font-family: 'FuturaBook';
    opacity: 0.6; }

.pay-plus-activated {
  width: 250px;
  display: flex;
  color: #5ac8f5;
  align-items: center;
  font-size: 15px;
  height: 36px; }

.print-ticket-container {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  margin-top: 30px;
  overflow: hidden;
  color: #262957;
  font-weight: bold;
  font-family: 'FuturaHeavy'; }
  .print-ticket-container .print-title {
    font-size: 20px; }
  .print-ticket-container .dimension {
    font-weight: normal;
    font-family: 'FuturaBook';
    opacity: 0.7; }
  .print-ticket-container .image {
    background-color: #e7e7e5;
    background-image: url("https://cdn.laddition.com/23/qr-code-support.png");
    background-position: 0 5px;
    background-repeat: no-repeat;
    background-size: 102%;
    width: 175px; }
  .print-ticket-container .right {
    flex-grow: 1;
    padding: 20px; }
  .print-ticket-container .buttons {
    display: flex;
    font-family: 'FuturaBook'; }
  .print-ticket-container .buttons a {
    background-color: #e84249;
    color: white;
    font-size: 18px;
    padding: 7px 18px;
    text-align: center;
    border-radius: 30px; }
  .print-ticket-container .buttons a:nth-child(2) {
    margin-left: 10px; }

.ticket-tip-container {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  width: 680px; }

.ticket-tip-container-inside {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

.ticket-tip-title {
  display: flex;
  justify-content: space-between; }

.ticket-tip-add {
  cursor: pointer;
  font-weight: bold; }


.export-banner-container {
  width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .export-banner-container h2 {
    align-self: flex-start; }

.banner-export-empty {
  height: 150px;
  width: 600px;
  border-radius: 10px;
  border: 7px dashed #e84249;
  color: #e84249;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .banner-export-empty p {
    font-size: 24px; }

.banner-export-mini {
  height: 200px; }

.banner-export-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .banner-export-image img {
    margin-bottom: 20px; }

.one-menu-item {
  display: flex;
  align-items: center;
  float: left;
  width: 23%;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  margin: 1%;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
  position: relative; }
  .one-menu-item h4 {
    color: #262957; }
  .one-menu-item p {
    font-size: 14px;
    color: #262957; }
  .one-menu-item .item-description-response {
    color: #5ac8f5; }
  .one-menu-item .item-description {
    width: 100%;
    padding: 20px 20px 10px 20px; }
    .one-menu-item .item-description h4 {
      margin: 0;
      margin-bottom: 30px; }
    .one-menu-item .item-description p {
      margin: 0; }
    .one-menu-item .item-description .item-description-response {
      margin-bottom: 10px;
      margin-top: 5px; }
  .one-menu-item:hover {
    cursor: pointer; }

.item-selected {
  box-shadow: none; }
  .item-selected svg {
    position: absolute;
    fill: #5ac8f5;
    top: 0;
    right: 0;
    margin: 5px; }

.list-menu-container {
  width: 80%;
  /* Spinner Circle Rotation */ }
  .list-menu-container:after {
    content: '';
    display: table;
    clear: both; }
  .list-menu-container .sp {
    width: 16px;
    height: 16px;
    clear: both;
    margin: 0 auto; }
  .list-menu-container .sp-circle {
    border: 4px rgba(232, 66, 73, 0.25) solid;
    border-top: 4px #e84249 solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 1s infinite linear; }

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

.description-menu-selector {
  color: #262957; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

.ubereats_pos_status_container {
  margin-top: 20px;
  font-size: 20px;
  font-weight: normal;
  text-align: center; }

.ubereats_pos_status {
  font-weight: bold;
  text-decoration: underline; }

.ubereats_pos_status_enabled {
  color: #17b717; }

.ubereats_pos_status_disabled {
  color: #00509b; }

.ubereats_pos_status_unknow {
  color: #f6950e; }

.ubereats_pos_status_container {
  margin-top: 20px;
  font-size: 20px;
  font-weight: normal;
  text-align: center; }

.ubereats_pos_status {
  font-weight: bold;
  text-decoration: underline; }

.ubereats_pos_status_enabled {
  color: #17b717; }

.ubereats_pos_status_disabled {
  color: #00509b; }

.ubereats_pos_status_unknow {
  color: #f6950e; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

#brand-channel-container {
  width: 680px;
  min-width: 680px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto; }
  #brand-channel-container h3 {
    display: flex; }
    #brand-channel-container h3 .red-button {
      margin-left: 20px; }

.MuiAccordionDetails-root {
  flex-direction: column; }

#brand-channel-container {
  display: flex;
  flex-direction: column; }
  #brand-channel-container p {
    color: #262957; }
  #brand-channel-container .red-button {
    margin: 0; }
  #brand-channel-container .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.partner-channel-container {
  background-color: white;
  padding: 30px 20px;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center; }

.brand-channel-search-container {
  margin-left: 30px; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px;
  margin-top: 15px; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }


.externalIdAssignator {
  display: flex;
  color: white;
  font-size: 22px;
  width: 80%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px; }
  .externalIdAssignator .externalIdAssignator-container {
    display: flex;
    flex-direction: column;
    min-width: 300px; }
    .externalIdAssignator .externalIdAssignator-container .externalIdAssignator-head {
      border-bottom: 1px solid #292929;
      display: flex;
      justify-content: space-between;
      font-size: 25px; }
    .externalIdAssignator .externalIdAssignator-container .externalIdAssignator-body {
      display: flex;
      flex-direction: column; }
    .externalIdAssignator .externalIdAssignator-container .externalIdAssignator-row {
      justify-content: space-between;
      display: flex;
      margin: 0; }
      .externalIdAssignator .externalIdAssignator-container .externalIdAssignator-row p {
        margin: 10px; }
  .externalIdAssignator .MuiSelect-select {
    color: white; }

.misterBooking-cancelNote-container-input {
	display: flex;
	margin-top: 30px;
	justify-content: space-around;
	align-items: center;
}

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.container-mews-default-setting {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 4px;
  padding: 20px 60px; }

.container-mews-documentation {
  display: flex;
  flex-direction: column; }
  .container-mews-documentation p {
    color: #262957; }

.mewsDocLinks {
  display: flex;
  align-items: center;
  justify-content: center; }
  .mewsDocLinks svg {
    margin-right: 3px; }

.gestion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 630px; }

.gestion-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }

.partnerconfig-configuration-container {
  width: 100% !important;
  margin: 0 !important; }
  .partnerconfig-configuration-container .mewsSystemsDocLink {
    overflow: scroll;
    text-align: center; }
    .partnerconfig-configuration-container .mewsSystemsDocLink a {
      color: #262957;
      font-family: 'FuturaBook';
      font-size: 13px; }
    .partnerconfig-configuration-container .mewsSystemsDocLink svg {
      width: 20px; }

.configuration-bottom {
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'FuturaBook';
  font-size: 16px;
  color: #e84249;
  min-height: 51px; }
  .configuration-bottom .progress-bar-container {
    width: 40%;
    display: flex;
    align-items: center; }
    .configuration-bottom .progress-bar-container span {
      font-size: 12px;
      padding: 0 10px;
      transition: color 0.3s linear; }
    .configuration-bottom .progress-bar-container .progress-bar {
      width: 50%;
      border-radius: 12px; }
      .configuration-bottom .progress-bar-container .progress-bar div {
        background-color: #e84249; }
  .configuration-bottom .configuration-button-next {
    transition: color 0.3s linear;
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer; }
  .configuration-bottom .disabled {
    color: rgba(255, 255, 255, 0.5) !important; }
  .configuration-bottom:hover {
    background-color: #e84249;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; }
    .configuration-bottom:hover .progress-bar-container span {
      color: #fff; }
    .configuration-bottom:hover .progress-bar-container .progress-bar div {
      background-color: #fff; }
    .configuration-bottom:hover .configuration-button-next {
      color: #fff; }

.configuration-bottom-active {
  background-color: #e84249;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; }
  .configuration-bottom-active .progress-bar-container span {
    color: #fff; }
  .configuration-bottom-active .progress-bar-container .progress-bar div {
    background-color: #fff !important; }
  .configuration-bottom-active .configuration-button-next {
    color: #fff;
    margin-left: auto; }

.step-header {
  display: flex;
  align-items: center;
  padding: 0 16px; }
  .step-header img {
    width: 25% !important;
    position: relative !important;
    margin-left: auto;
    left: 0 !important; }
  .step-header h3 {
    margin: 0;
    color: #262957;
    font-family: 'FuturaBold';
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    .step-header h3 .card_action_help {
      margin-left: 10px; }

.link-ubereats-signup {
  color: #e84249;
  font-size: 11px;
  text-align: center;
  margin-bottom: 5px;
  cursor: pointer; }

.uuid-container {
  display: flex;
  flex-direction: column;
  padding: 0 16px 50px; }
  .uuid-container .partner-input-label {
    color: #262957 !important; }
  .uuid-container .input-button {
    width: 100%;
    margin-bottom: 0;
    margin-top: 0px; }
  .uuid-container a:hover {
    text-decoration: underline; }

.input-button-disabled {
  background-color: rgba(171, 171, 171, 0.2) !important;
  cursor: initial !important; }

.content-partner-token {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .content-partner-token .content-card-p {
    margin: 0; }

.expand-container {
  width: 30%;
  margin: 20px;
  z-index: 4; }
  .expand-container .content-card-3 {
    padding-bottom: 50px; }
    .expand-container .content-card-3 .content-card-p {
      margin: 0; }
    .expand-container .content-card-3 .red-button {
      margin: 10px;
      text-align: center; }
    .expand-container .content-card-3 a .red-button {
      margin: 3px; }
    .expand-container .content-card-3 .label-room-select,
    .expand-container .content-card-3 .label-paiement-select,
    .expand-container .content-card-3 .label-priceCategory-select {
      text-align: center; }
      .expand-container .content-card-3 .label-room-select p,
      .expand-container .content-card-3 .label-paiement-select p,
      .expand-container .content-card-3 .label-priceCategory-select p {
        display: none; }
    .expand-container .content-card-3 .label-paiement-select,
    .expand-container .content-card-3 .label-priceCategory-select {
      margin-top: -10px; }
    .expand-container .content-card-3 .paragraph-without-margin {
      margin: 5px 0;
      font-size: 14px;
      padding: 0 30px;
      color: #262957; }
  .expand-container .content-card-como {
    text-align: center; }
  .expand-container .button-partner-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
    .expand-container .button-partner-container .red-button {
      margin: 3px; }
  .expand-container .content-card-p {
    font-size: 14px;
    padding: 0 10px;
    color: #262957;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'NunitoSans'; }
  .expand-container .store-ubereats {
    flex-direction: column; }
    .expand-container .store-ubereats span {
      opacity: 0.7;
      font-style: italic;
      font-size: 12px; }
  .expand-container .expand-left {
    width: calc(67% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    left: 0;
    margin: 0 20px; }
  .expand-container .expand-center {
    width: calc(67% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    left: 16%;
    margin: 0 20px; }
  .expand-container .expand-right {
    width: calc(67% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    right: 0;
    margin: 0 20px; }
  .expand-container .expand-left-link {
    width: calc(100% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    left: 0;
    margin: 0 20px; }
  .expand-container .expand-center-link {
    width: calc(100% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    left: 0;
    margin: 0 20px; }
  .expand-container .expand-right-link {
    width: calc(100% - 40px);
    transition: width 0.3s linear;
    position: absolute;
    z-index: 9;
    right: 0;
    margin: 0 20px; }
  .expand-container .expand-content {
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    margin-top: 45px;
    transition: width 0.3s linear;
    padding: 16px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: space-around;
    max-height: 360px; }
    .expand-container .expand-content h4 {
      color: #262957;
      font-family: 'FuturaBook';
      font-size: 26px;
      font-weight: 300;
      margin: 0; }
    .expand-container .expand-content p {
      color: #868686;
      font-family: 'FuturaBook';
      font-size: 17px;
      font-weight: 300;
      line-height: 20px;
      text-align: inherit;
      margin: 10px 0px; }
    .expand-container .expand-content .expand-external-content {
      width: 37%;
      min-width: 37%;
      margin-right: 16px; }
      .expand-container .expand-content .expand-external-content img {
        width: 100%;
        border-radius: 20px; }
      .expand-container .expand-content .expand-external-content iframe {
        width: 100%;
        height: 317px; }
      .expand-container .expand-content .expand-external-content .react-slideshow-container {
        justify-content: center; }
        .expand-container .expand-content .expand-external-content .react-slideshow-container .react-slideshow-fade-wrapper {
          max-height: 340px; }
      .expand-container .expand-content .expand-external-content .react-slideshow-container + div.indicators > div.active {
        background: #e84249; }
      .expand-container .expand-content .expand-external-content .react-slideshow-container + div.indicators > div:hover {
        background: rgba(232, 66, 73, 0.4); }
      .expand-container .expand-content .expand-external-content .react-slideshow-container .nav span {
        border-color: #e84249; }
      .expand-container .expand-content .expand-external-content .react-slideshow-container .nav:last-of-type {
        margin-left: -40px; }
      .expand-container .expand-content .expand-external-content .react-slideshow-container .nav:first-of-type {
        margin-right: -40px; }
    .expand-container .expand-content .expand-external-description {
      display: flex;
      min-width: 62%;
      flex-direction: column;
      overflow: scroll; }
      .expand-container .expand-content .expand-external-description p {
        font-family: 'NunitoSans';
        font-size: 15px;
        margin: 5px 0; }
      .expand-container .expand-content .expand-external-description .missing-id-content {
        border-radius: 10px;
        overflow: hidden; }
        .expand-container .expand-content .expand-external-description .missing-id-content > div:first-of-type {
          border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
        .expand-container .expand-content .expand-external-description .missing-id-content .missing-id-suspends,
        .expand-container .expand-content .expand-external-description .missing-id-content .missing-id-actives {
          width: 30%; }
          .expand-container .expand-content .expand-external-description .missing-id-content .missing-id-suspends div,
          .expand-container .expand-content .expand-external-description .missing-id-content .missing-id-actives div {
            height: 140px;
            overflow-y: scroll; }
        .expand-container .expand-content .expand-external-description .missing-id-content h5 {
          color: #262957;
          margin: 0;
          font-family: 'FuturaBook';
          font-size: 16px; }
        .expand-container .expand-content .expand-external-description .missing-id-content .actives_suspends_products {
          display: flex;
          width: 100%;
          justify-content: space-around;
          flex-wrap: wrap;
          text-align: center; }
          .expand-container .expand-content .expand-external-description .missing-id-content .actives_suspends_products p {
            color: #262957;
            width: 50%; }
      .expand-container .expand-content .expand-external-description .incomplete_text_expand {
        margin-top: 20px;
        margin-bottom: 0; }
      .expand-container .expand-content .expand-external-description .red-button {
        margin: 10px;
        text-align: center; }
  .expand-container .after-left-link:after {
    content: '';
    position: absolute;
    left: 14%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }
  .expand-container .after-left:after {
    content: '';
    position: absolute;
    left: 20%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }
  .expand-container .after-center-link:after {
    content: '';
    position: absolute;
    left: 48%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }
  .expand-container .after-center:after {
    content: '';
    position: absolute;
    left: 48%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }
  .expand-container .after-right-link:after {
    content: '';
    position: absolute;
    right: 15%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }
  .expand-container .after-right:after {
    content: '';
    position: absolute;
    right: 25%;
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    clear: both; }

.container-mews-select {
  display: flex;
  justify-content: center; }

.select-mews-data {
  min-width: 170px; }

@media only screen and (min-width: 2000px) {
  .react-slideshow-fade-wrapper {
    max-height: 610px !important; }
  .missing-id-suspends div,
  .missing-id-actives div {
    height: 320px !important; } }

.Mui-expanded svg {
  color: #e84249; }

.content-card-store {
  display: flex;
  justify-content: space-between; }

.content-card-store:last-child {
  margin-bottom: 50px; }

.cardConfig-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px; }

.blue {
  color: #5ac8f5; }

.blury {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /*  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px); */
  top: 0;
  left: 0;
  z-index: 4; }

.item-enter {
  opacity: 0; }

.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in; }

.item-exit {
  opacity: 1; }

.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in; }

/* @supports not (backdrop-filter: blur(4px)) {
  .blury {
    background-color: rgba(0, 0, 0, 0.8);
  }
} */
.z-index0 {
  z-index: 0; }

.z-index5 {
  z-index: 5 !important; }

.divider {
  width: 80%;
  height: 3px;
  border-radius: 3px;
  background-color: #ababab;
  opacity: 0.5;
  margin: 0 auto; }

.partnerconfig-content-container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 100px;
  padding-bottom: 40px; }

.title-content {
  margin: 0 120px; }

.partners-link-espace-client {
  position: absolute;
  left: 50px;
  top: 70px; }
  .partners-link-espace-client a {
    color: white;
    display: flex;
    align-items: center;
    font-size: 20px; }

.search-bar {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 15px auto;
  cursor: pointer;
  transition: all 0.5s; }
  .search-bar svg {
    fill: #292929;
    margin: 0 1rem; }
  .search-bar input {
    height: 38px;
    font-size: 16px;
    width: 250px;
    opacity: 1;
    color: #292929;
    border: none;
    outline: none;
    font-family: 'FuturaBook';
    border-radius: 19px;
    transition: all 0.5s; }

.search-bar-active {
  width: 300px;
  justify-content: left; }

.search-tag-content {
  padding: 0 120px;
  background-color: transparent;
  font-family: 'FuturaBook';
  display: flex;
  align-items: center; }
  .search-tag-content .search-tag-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; }
    .search-tag-content .search-tag-container .tag-container {
      height: 39px;
      background-color: rgba(255, 255, 255, 0.3);
      display: flex;
      align-items: center;
      border-radius: 19.5px;
      transition: all 0.3s linear;
      padding: 0 35px;
      margin: 3px 10px; }
      .search-tag-content .search-tag-container .tag-container p {
        margin: 0 10px;
        font-size: 16px;
        color: white;
        transition: all 0.3s linear; }
      .search-tag-content .search-tag-container .tag-container:hover {
        background: #5ac8f5;
        cursor: pointer; }
        .search-tag-content .search-tag-container .tag-container:hover p {
          color: #fff; }
    .search-tag-content .search-tag-container .tag-selected {
      background: #5ac8f5 !important; }
      .search-tag-content .search-tag-container .tag-selected p {
        color: #fff !important; }
  .search-tag-content .separator {
    height: 3px;
    width: 100%;
    opacity: 0.5;
    background: #ababab;
    border-radius: 2.5px;
    margin-top: 10px; }

.step-header img {
  margin-top: 2px; }

.partnerconfig-export-container {
  width: 30%;
  margin: 20px;
  position: relative; }
  .partnerconfig-export-container .partnerconfig-partner-card {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
    min-height: 184px;
    position: relative;
    transition: all 0.3s linear;
    min-height: 180px;
    height: calc(100% - 30px);
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .partnerconfig-export-container .partnerconfig-partner-card img {
      width: 60%;
      cursor: initial; }
    .partnerconfig-export-container .partnerconfig-partner-card:hover {
      box-shadow: 0 12px 28px #979797; }
    .partnerconfig-export-container .partnerconfig-partner-card :-webkit-scrollbar {
      display: none; }
    .partnerconfig-export-container .partnerconfig-partner-card .deliveroo-id span {
      font-size: 20px;
      font-weight: bold;
      background-color: #e6e6e6;
      text-align: center;
      padding: 5px 10px;
      border-radius: 5px; }
  .partnerconfig-export-container .partner-with-url:hover .bottom-card p {
    color: #e84249; }

@media only screen and (min-width: 1500px) {
  .partnerconfig-export-container .partnerconfig-partner-card {
    min-height: 200px; } }

@media only screen and (min-width: 2000px) {
  .partnerconfig-export-container .partnerconfig-partner-card {
    min-height: 250px; }
  .partnerconfig-export-container .content-card-p {
    font-size: 19px; }
  .partnerconfig-export-container .button-partner-container .red-button span {
    font-size: 16px; }
  .expand-content {
    max-height: 610px !important; } }

@media only screen and (min-width: 2500px) {
  .partnerconfig-export-container .partnerconfig-partner-card {
    min-height: 300px; } }

.inactif-card {
  box-shadow: 0 3px 7px #979797 !important;
  background-color: #f6f6f6 !important; }
  .inactif-card:hover i {
    box-shadow: 0 3px 7px #979797; }
  .inactif-card:hover .bottom-card p {
    color: #868686 !important; }

.logo-and-error {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .logo-and-error img {
    width: 50%;
    position: relative;
    left: 0; }
  .logo-and-error p {
    margin: 0 1rem;
    color: #ababab;
    font-size: 17px;
    text-align: center; }

.card_top_action {
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 1;
  color: #cacaca !important;
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between; }
  .card_top_action .card_action {
    cursor: pointer;
    color: #cacaca !important;
    margin-left: 10px;
    svg-width: 50px; }
    .card_top_action .card_action :hover {
      color: #5ac8f5; }
  .card_top_action .card_action_rigth {
    display: flex; }

.bottom-card {
  bottom: 0;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'FuturaBook';
  font-size: 16px;
  color: #868686; }
  .bottom-card p {
    margin-left: 1rem; }
  .bottom-card div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-left: auto;
    margin-right: 1rem; }
    .bottom-card div label {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      color: #868686; }
  .bottom-card .form-switch {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    bottom: 10px; }
  .bottom-card .form-switch i {
    position: relative;
    display: inline-block;
    margin-left: 1rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear; }
  .bottom-card .form-switch i::before {
    content: '';
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    -webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
            transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear; }
  .bottom-card .form-switch i::after {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    -webkit-transform: translate3d(2px, 2px, 0);
            transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out; }
  .bottom-card .form-switch:active i::after {
    width: 28px;
    -webkit-transform: translate3d(2px, 2px, 0);
            transform: translate3d(2px, 2px, 0); }
  .bottom-card .form-switch:active input:checked + i::after {
    -webkit-transform: translate3d(16px, 2px, 0);
            transform: translate3d(16px, 2px, 0); }
  .bottom-card .form-switch input {
    display: none; }
  .bottom-card .form-switch input:checked + i {
    background-color: #5ac8f5; }
  .bottom-card .form-switch input:checked + i::before {
    -webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
            transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
  .bottom-card .form-switch input:checked + i::after {
    -webkit-transform: translate3d(22px, 2px, 0);
            transform: translate3d(22px, 2px, 0); }

.partnerconfig-partner-title {
  font-weight: bold !important;
  font-size: 25px !important;
  color: #262957 !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; }

.partner-title-header {
  position: absolute;
  right: 10px; }

.partner-configuration-card .logo-and-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 20px);
  justify-content: space-around; }
  .partner-configuration-card .logo-and-description img {
    width: 50%;
    position: relative;
    left: 0;
    top: 0; }
  .partner-configuration-card .logo-and-description p {
    margin: 0 1rem;
    color: #ababab;
    font-size: 15px;
    text-align: center;
    overflow: scroll;
    height: 55px; }

.partner-configuration-card:hover {
  box-shadow: 0 6px 14px #979797;
  cursor: default; }
  .partner-configuration-card:hover .bottom-card .configuration-button {
    cursor: pointer;
    color: #e84249 !important; }
  .partner-configuration-card:hover .bottom-card p {
    color: inherit !important; }

.partner-configuration-card .connector_configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 20px);
  margin-top: 30px;
  justify-content: space-around; }
  .partner-configuration-card .connector_configuration img {
    width: 25%;
    position: absolute;
    right: 15px;
    top: 5px;
    left: auto; }
  .partner-configuration-card .connector_configuration p {
    margin: 0 1rem;
    color: #ababab;
    font-size: 15px;
    text-align: center;
    overflow: scroll; }
  .partner-configuration-card .connector_configuration .connector_configuration_title {
    color: #7d7b7b;
    font-weight: bold; }
  .partner-configuration-card .connector_configuration .connector_configuration_button {
    background-color: white;
    color: #e84249;
    border: 1px solid #e84249;
    border-radius: 15px;
    font-size: 12px;
    height: 25px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: center;
    cursor: pointer;
    text-align: center; }
    .partner-configuration-card .connector_configuration .connector_configuration_button:hover {
      color: white;
      background-color: #e84249; }

.partner-configuration-card .princing-info {
  color: #262957;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding-left: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px; }

.partnerconfig-partner-card .princing-info,
.partner-configuration-card .princing-info {
  color: #262957;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px; }

.bottom-card {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  font-family: 'FuturaBook';
  font-size: 16px;
  color: #868686; }
  .bottom-card p {
    margin-left: 1rem; }
  .bottom-card .configuration-button {
    color: #e84249;
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    align-items: center; }
    .bottom-card .configuration-button svg {
      margin-left: 2px;
      fill: #e84249; }

.partner-config-lock-icon {
  position: inherit !important;
  width: 45px !important;
  margin-bottom: 10px; }

.general-option-container {
  padding: 0px 25px 25px 25px; }

.general-option-button {
  position: absolute !important;
  right: 50px;
  z-index: 100; }

.dashboard-module {
  width: 30%;
  background-color: #fff;
  border-radius: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.23);
  min-height: 184px;
  position: relative;
  transition: all 0.3s linear; }
  .dashboard-module a {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; }
  .dashboard-module p {
    color: #262957;
    font-family: 'FuturaHeavy';
    font-size: 26px;
    transition: color 0.7s ease; }
    .dashboard-module p:after {
      margin-top: 8px;
      content: '';
      display: block;
      height: 6px;
      background: #e84249;
      width: 0;
      transition: width 0.7s ease; }
  .dashboard-module p.disabled {
    color: #acacac; }
  .dashboard-module:hover {
    cursor: pointer;
    box-shadow: 0 12px 28px #979797; }
    .dashboard-module:hover p:after {
      width: 30%; }

.content-container {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.module-container {
  width: 100%;
  display: flex;
  justify-content: center; }

.tabs-container {
  width: 100%;
  border-radius: 6px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 20px; }
  .tabs-container a {
    width: 100%;
    text-decoration: none;
    color: #4a4a4a;
    text-align: center; }
    .tabs-container a p {
      color: #262957;
      font-family: 'FuturaHeavy';
      font-size: 19px;
      line-height: 24px; }

.header-container li:nth-child(2) a::after, .header-container li:nth-child(3) a::after, .header-container li:last-child a::after {
  content: '';
  position: absolute;
  margin-top: -3px;
  left: 0;
  right: 0;
  border-bottom: 2px solid #cecece; }

.header-container {
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0; }
  .header-container li:nth-child(2) a::after, .header-container li:nth-child(3) a::after {
    left: 0;
    right: 0; }
  .header-container li:nth-child(2) a.topLine::after, .header-container li:nth-child(3) a.topLine::after {
    top: 1.73rem; }
  .header-container li:last-child a::after {
    left: 0;
    width: 50%;
    right: 0; }
  .header-container li:last-child a.topLine::after {
    top: 1.73rem; }
  .header-container li:first-child a::before {
    content: '';
    position: absolute;
    bottom: 1px;
    right: 0;
    width: 50%;
    border-bottom: 2px solid #cecece; }
  .header-container li:first-child a.topLine::before {
    top: 1.73rem; }
  .header-container li {
    display: inline;
    position: relative;
    width: 100%; }
    .header-container li a {
      display: inline-block;
      position: relative;
      cursor: pointer; }
      .header-container li a hr {
        background-color: #262957;
        height: 4px;
        width: 0.5rem;
        text-align: center;
        margin: 0 auto;
        border: none;
        position: relative;
        z-index: 3; }
      .header-container li a p {
        font-weight: bold;
        font-size: 12px;
        font-family: 'FuturaBold';
        text-transform: uppercase;
        margin-top: 0;
        color: #7e8198 !important; }
  .header-container .active p {
    color: #e84249 !important; }
  .header-container .active hr {
    width: 5rem;
    background-color: #e84249;
    -webkit-animation: separator-width 300ms ease-out forwards;
            animation: separator-width 300ms ease-out forwards; }
  .header-container .disabled-section {
    pointer-events: none;
    opacity: 0.6; }

@-webkit-keyframes separator-width {
  0% {
    width: 0; }
  100% {
    width: 25%; } }

@keyframes separator-width {
  0% {
    width: 0; }
  100% {
    width: 25%; } }

.leaflet-container {
  height: 530px;
  width: 530px;
  margin: 50px auto;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); }

#container-info-restau {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  display: flex;
  flex-direction: column; }

#form-info-restau {
  display: flex;
  flex-direction: column;
  margin-top: -20px; }

#form-info-1 {
  display: flex;
  flex-grow: 1;
  margin: 5px; }

#form-info-2 {
  display: flex;
  flex-grow: 1;
  margin: 5px; }

.form-section-50 {
  flex-grow: 1;
  margin: 5px;
  width: 100%;
  display: flex;
  flex-direction: column; }

.form-section-row {
  display: flex;
  margin: -4px; }

.flex-item-20 {
  flex-grow: 20; }

.flex-item-80 {
  flex-grow: 80; }

.flex-item-33 {
  flex-grow: 33; }

.flex-item-66 {
  flex-grow: 66; }

.flex-item-100 {
  flex-grow: 100; }

.tab-module {
  width: 50%;
  background-color: #fff;
  border-radius: 6px;
  color: #4a4a4a;
  box-shadow: 0 8px 16px rgba(1, 1, 1, 0.19);
  padding: 20px;
  margin-top: 52px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column; }

#infoRestau-name-value {
  color: #e84249;
  font-size: 3.3em;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: 'FuturaBold';
  line-height: 50px; }

.info-Restau-section-title {
  color: #2b305e;
  font-size: 27px; }

#long-lat-display {
  width: 100%;
  text-align: center;
  margin: auto;
  position: relative;
  top: -37px;
  background-color: white;
  padding-top: 17px;
  padding-bottom: 17px;
  border-radius: 5px;
  font-weight: 600;
  font-family: 'FuturaBook'; }

#form-info-restau textarea,
#form-info-restau input {
  border: none;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: -1px; }

#form-info-restau input {
  height: 25px; }

#form-info-restau label {
  font-size: 25px;
  font-family: 'FuturaBook';
  color: #7e8198; }

.form-description-container {
  width: 100%;
  position: relative; }

.form-caracters-remaning {
  color: #7e8198;
  width: 200px;
  text-align: right;
  position: absolute;
  right: 0; }

.locate-button {
  background-color: #e84249;
  color: white;
  border: none;
  padding: 10px;
  padding: 10px;
  text-align: center;
  vertical-align: center;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'FuturaBook';
  font-size: 15px; }

.locate-button-init:hover {
  opacity: 0.7; }

.locate-button-progress {
  background-color: #bbbbbb; }

.locate-button-success {
  background-color: #6ccc68; }

.locate-button-fail {
  background-color: #fa8888; }

#hours .time-picker div .time {
  vertical-align: super; }

#hours {
  font-family: 'Futura';
  /* .content-days {
      margin-top: .2rem;
      color: $light-grey;
      font-weight: 100;
      font-size: 15px;
      button {
        &.selected {
          background: $ciel-addition;
          color: $grey;
        }
      }
    }  */ }
  #hours .form-hours {
    margin-bottom: 72px; }
  #hours img.calendar {
    vertical-align: middle;
    margin-right: 0.9rem;
    width: 20px; }
  #hours .time-picker {
    display: flex;
    align-items: center; }
    #hours .time-picker div.start p.subtitle, #hours .time-picker div.end p.subtitle {
      margin-left: 0.5rem;
      letter-spacing: 0.5px; }
    #hours .time-picker div.start .arrow, #hours .time-picker div.end .arrow {
      width: 0.6rem;
      margin: 0 0.5rem;
      margin-bottom: 0.3rem; }
    #hours .time-picker div .select-styles {
      display: inline-block;
      margin: 0 0.5rem;
      border: 1px solid #ccc;
      width: 100px;
      border-radius: 5px;
      overflow: hidden;
      padding: 0.2rem 0.3rem;
      background: #fafafa url(/static/media/fleche-bas.2f862a22.svg) no-repeat 90% 50%;
      background-size: 13px;
      position: relative; }
      #hours .time-picker div .select-styles .select {
        text-align-last: center;
        padding: 5px 8px;
        width: 90px;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        letter-spacing: 0.5px; }
      #hours .time-picker div .select-styles input.rc-time-picker-input {
        box-shadow: inherit;
        border: inherit;
        background: inherit;
        letter-spacing: 0.5px;
        color: #262957;
        font-family: 'Futura';
        font-size: 16px; }
    #hours .time-picker div .time {
      width: 25px;
      bottom: 0.6rem; }
    #hours .time-picker .content-time {
      display: inline-block; }
  #hours .days {
    height: 30px;
    width: 285px;
    vertical-align: sub;
    background: #fafafa; }
    #hours .days span {
      border-right: 1px solid #a9aabc; }
      #hours .days span:last-child {
        border-right: inherit; }
      #hours .days span span {
        border-right: inherit; }
  #hours .content-days .select-days {
    display: inline-block; }
    #hours .content-days .select-days .bloc-day {
      color: #a9aabc;
      background: white;
      border-right: 1px solid #cfd0d6;
      border-top: 1px solid #a9aabc;
      border-bottom: 1px solid #a9aabc;
      width: 50px;
      height: 40px;
      padding: 0.1rem 0;
      letter-spacing: normal;
      display: inline-block; }
      #hours .content-days .select-days .bloc-day.selected {
        background: #5ac8f5;
        color: white; }
      #hours .content-days .select-days .bloc-day span {
        display: block;
        padding-top: 0.5rem;
        text-align: center; }
      #hours .content-days .select-days .bloc-day:last-child {
        border-radius: 0 5px 5px 0; }
      #hours .content-days .select-days .bloc-day:first-child {
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #a9aabc; }

.success {
  background-color: #5ac8f5;
  padding: 0.6rem;
  border-radius: 3px;
  margin: 1rem 0;
  color: white;
  text-align: center; }

.subtitle {
  color: #636363;
  font-size: 16px;
  margin-bottom: 5px; }

.rc-time-picker {
  display: inline-block;
  box-sizing: border-box; }
  .rc-time-picker input {
    box-shadow: inherit;
    border: inherit;
    background: inherit; }

.rc-time-picker * {
  box-sizing: border-box; }

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: text; }
  .rc-time-picker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed; }

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5; }

.rc-time-picker-panel-narrow {
  max-width: 113px; }

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto; }

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9; }

.rc-time-picker-panel-input-invalid {
  border-color: red; }

.rc-time-picker-panel-clear-btn {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0; }

.rc-time-picker-panel-clear-btn-icon:hover:after {
  color: #666; }

.rc-time-picker-panel-clear-btn-icon:after {
  content: 'x';
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease; }

.rc-time-picker-panel-select-active {
  overflow-y: auto; }

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
  color: #262957;
  font-size: 'Futura'; }
  .rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
    overflow-x: hidden; }
  .rc-time-picker-panel-select:last-child {
    border-right: 0;
    overflow-x: hidden; }
  .rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%; }
    .rc-time-picker-panel-select ul li {
      list-style: none;
      box-sizing: content-box;
      margin: 0;
      padding: 0 0 0 16px;
      width: 100%;
      height: 24px;
      line-height: 24px;
      text-align: left;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none; }
      .rc-time-picker-panel-select ul li:hover {
        background: #edfaff; }

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold; }

li.rc-time-picker-panel-select-option-disabled {
  color: #ccc; }

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed; }

.hour-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .hour-container h4 {
    color: #262957;
    font-size: 16px; }

.submit .red-button {
  text-align: right;
  margin: 0 24px; }

.closing-date-container {
  display: flex;
  padding: 0;
  list-style: none;
  flex-wrap: wrap; }

.closing-date-item-container {
  background-color: #fff;
  border-radius: 6px;
  color: #4a4a4a;
  box-shadow: 0 8px 16px rgba(1, 1, 1, 0.19);
  padding: 20px;
  margin: 10px; }

.closing-days {
  display: flex;
  justify-content: center;
  margin: 10px; }
  .closing-days .start-date-container,
  .closing-days .end-date-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .closing-days .start-date-container .start,
    .closing-days .start-date-container .end,
    .closing-days .end-date-container .start,
    .closing-days .end-date-container .end {
      display: flex;
      justify-content: center; }
      .closing-days .start-date-container .start .select-styles,
      .closing-days .start-date-container .end .select-styles,
      .closing-days .end-date-container .start .select-styles,
      .closing-days .end-date-container .end .select-styles {
        border: 1px solid #ccc;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        padding: 0.2rem 0.3rem;
        background-size: 13px;
        position: relative;
        display: inline-block;
        background-color: #fff; }
        .closing-days .start-date-container .start .select-styles .select,
        .closing-days .start-date-container .end .select-styles .select,
        .closing-days .end-date-container .start .select-styles .select,
        .closing-days .end-date-container .end .select-styles .select {
          text-align-last: center;
          padding: 5px 8px;
          width: 90px;
          border: none;
          box-shadow: none;
          background: transparent;
          letter-spacing: 0.5px;
          color: #262957;
          font-size: 15px;
          font-family: 'Futura'; }
          .closing-days .start-date-container .start .select-styles .select:focus,
          .closing-days .start-date-container .end .select-styles .select:focus,
          .closing-days .end-date-container .start .select-styles .select:focus,
          .closing-days .end-date-container .end .select-styles .select:focus {
            outline: 0; }
  .closing-days .start-title,
  .closing-days .end-title {
    width: 10%;
    text-align: center; }

#settings .red-button {
  text-align: center; }

#calendar {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 8px 0px #bfbcbc;
  position: relative;
  width: 325px;
  display: flex;
  justify-content: center;
  margin: 0 auto; }
  #calendar .close-icon-calendar {
    margin-right: 1.7rem;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #262957;
    background-size: 5px;
    position: absolute;
    top: 20px;
    left: 10px;
    fill: #fff !important;
    cursor: pointer;
    z-index: 4; }
  #calendar.index-0 {
    bottom: 10.2rem; }
  #calendar.index-1 {
    bottom: 7.2rem; }
  #calendar.index-2 {
    bottom: 4.2rem; }
  #calendar.index-3 {
    bottom: 1.2rem; }
  #calendar .valid-date {
    margin: 0; }
  #calendar .DayPicker-Caption div {
    font-family: 'FuturaBook'; }

.arrow {
  width: 0.6rem;
  margin: 0 0.5rem;
  vertical-align: super; }

.close-icon {
  margin-left: 0.3rem;
  position: relative;
  fill: #262957 !important;
  cursor: pointer; }

.container {
  padding: 0;
  display: inline-block;
  height: 335px; }
  .container .red-button {
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 20px; }

.DayPicker-Day:focus {
  outline: 0; }

.DayPicker-Day--today {
  color: #e84249; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #5ac8f5; }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):focus {
    outline: 0; }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: rgba(90, 200, 245, 0.8); }

.Selectable
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #dbf8ff;
  color: #262957; }

.Selectable .DayPicker-Day {
  border-radius: 0; }

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%; }

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%; }

.DayPicker-Months {
  margin-left: 6px; }

.DayPicker-wrapper:focus {
  outline: 0; }

.DayPicker-Caption div {
  font-size: 1rem;
  font-family: 'FuturaBook';
  margin-top: 0.3rem; }

.DayPicker-NavBar span.DayPicker-NavButton--next {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #e84249 url(/static/media/arrow-white-right.d5f76809.svg) no-repeat center;
  background-size: 5px; }
  .DayPicker-NavBar span.DayPicker-NavButton--next:focus {
    outline: 0; }

.DayPicker-NavBar span.DayPicker-NavButton--prev {
  margin-right: 1.7rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #e84249 url(/static/media/arrow-white-left.c973c170.svg) no-repeat center;
  background-size: 5px; }
  .DayPicker-NavBar span.DayPicker-NavButton--prev:focus {
    outline: 0; }

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px; }

.list-container {
  max-width: 100%;
  margin: 0 auto; }
  .list-container ul {
    display: flex;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center; }

.add-picture-container {
  width: 300px;
  margin: 0 23px 30px 23px; }

.gallery {
  /* width: 100%;
  padding: 0 8px;
  margin-bottom: 16px; */ }
  .gallery img {
    width: 100%;
    height: auto; }

.card-grid {
  width: 300px;
  height: 181px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative; }
  .card-grid img {
    cursor: pointer;
    transition: all 1s ease;
    width: 450px;
    -webkit-transform: translate(-10%, -10%);
    transform: translate(-10%, -10%); }
    .card-grid img:hover {
      width: 400px; }

div.card-description {
  width: 270px;
  height: 95px;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: 'FuturaBook';
  font-size: 16px;
  color: #262957;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative; }
  div.card-description .content-name {
    line-height: 2px; }
  div.card-description .content-description {
    width: 80%;
    margin: 0 auto;
    color: #b7b7b7; }
  div.card-description .trash-container {
    position: absolute;
    bottom: 5px;
    right: 5px; }

.card {
  list-style: none;
  margin: 0 23px 30px 23px; }
  .card a {
    color: #e84249 !important; }
  .card .edit-picture-button {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    color: #e84249;
    top: 5px;
    left: 5px;
    cursor: pointer;
    transition: all 500ms;
    background-color: white;
    box-shadow: #b7b7b7;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25); }
  .card .edit-picture-button:hover {
    background-color: #e84249;
    color: white; }

.new-picture-container {
  width: 294px;
  height: 300px;
  border: 3px dashed rgba(232, 66, 73, 0.4);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.label-file {
  cursor: pointer;
  font-family: 'FuturaBold';
  color: #e84249;
  font-size: 19px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 16px; }

.input-file {
  display: none; }

.icon-add-export {
  margin-left: auto;
  margin-right: auto;
  background-color: #e84249;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-add-export svg {
    font-size: 60px;
    color: white; }

.dates-icon,
.info-restau-icon,
.pictures-icon {
  width: 30px;
  height: 30px; }

.grey-color {
  fill: #7e8198; }

.red-color {
  fill: #e84249; }

.tabs-container {
  width: 100%;
  border-radius: 6px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 20px; }
  .tabs-container a {
    width: 100%;
    text-decoration: none;
    color: #4a4a4a;
    text-align: center; }
    .tabs-container a p {
      color: #262957;
      font-family: "FuturaHeavy";
      font-size: 19px;
      line-height: 24px; }

.header-container {
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0; }
  .header-container .active p {
    color: #e84249 !important; }
  .header-container .active hr {
    width: 5rem;
    background-color: #e84249;
    -webkit-animation: separator-width 300ms ease-out forwards;
            animation: separator-width 300ms ease-out forwards; }
  .header-container .disabled-section {
    pointer-events: none;
    opacity: 0.6; }

@-webkit-keyframes separator-width {
  0% {
    width: 0; }
  100% {
    width: 25%; } }

@keyframes separator-width {
  0% {
    width: 0; }
  100% {
    width: 25%; } }

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px; }

.list-container {
  max-width: 100%;
  margin: 0 auto; }
  .list-container ul {
    display: flex;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center; }

.add-picture-container {
  width: 300px;
  margin: 0 23px 30px 23px; }

.container-title-load-qrcode {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.gallery {
  /* width: 100%;
  padding: 0 8px;
  margin-bottom: 16px; */
  cursor: all-scroll; }
  .gallery img {
    width: 100%;
    height: auto; }

.card-grid {
  width: 300px;
  height: 181px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative; }
  .card-grid img {
    cursor: pointer;
    transition: all 1s ease;
    width: 450px;
    -webkit-transform: translate(-10%, -10%);
    transform: translate(-10%, -10%); }
    .card-grid img:hover {
      width: 400px; }

div.card-description {
  width: 270px;
  height: 95px;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: 'FuturaBook';
  font-size: 16px;
  color: #262957;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative; }
  div.card-description .content-name {
    line-height: 2px; }
  div.card-description .content-description {
    width: 80%;
    margin: 0 auto;
    color: #b7b7b7; }
  div.card-description .trash-container {
    position: absolute;
    bottom: 5px;
    right: 5px; }

.qrcode-container {
  position: absolute;
  bottom: 5px;
  left: 5px; }

.card {
  list-style: none;
  margin: 0 23px 30px 23px; }
  .card a {
    color: #e84249 !important; }
  .card .edit-picture-button {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    color: #e84249;
    top: 5px;
    left: 5px;
    cursor: pointer;
    transition: all 500ms;
    background-color: white;
    box-shadow: #b7b7b7;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.25); }
  .card .edit-picture-button:hover {
    background-color: #e84249;
    color: white; }

.new-picture-container {
  width: 294px;
  height: 300px;
  border: 3px dashed rgba(232, 66, 73, 0.4);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.label-file {
  cursor: pointer;
  font-family: 'FuturaBold';
  color: #e84249;
  font-size: 19px;
  font-weight: bold;
  margin: 0 auto;
  margin-bottom: 16px; }

.input-file {
  display: none; }

.icon-add-export {
  margin-left: auto;
  margin-right: auto;
  background-color: #e84249;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-add-export svg {
    font-size: 60px;
    color: white; }

.dates-icon,
.info-restau-icon,
.pictures-icon {
  width: 30px;
  height: 30px; }

.grey-color {
  fill: #7e8198; }

.red-color {
  fill: #e84249; }

.event-category-title {
  font-size: 2em;
  margin: 10px;
  display: block;
  -webkit-margin-before: 1.33em;
          margin-block-start: 1.33em;
  -webkit-margin-after: 1.33em;
          margin-block-end: 1.33em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-family: 'FuturaBook';
  color: #4e708d; }

.event-category-title-selected {
  font-family: 'FuturaHeavy';
  color: #e84249; }

.event-category-panel {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  min-width: 400px;
  width: 60%; }

.event-category-panel svg {
  color: #e84249;
  font-size: 1.8em; }

.event-paper {
  font-family: 'FuturaBook';
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  background-color: #fefefe;
  padding: 20px 30px 20px 30px;
  margin: 10px;
  color: #4e708d;
  width: 100%;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 200ms; }

.event-paper:hover {
  background-color: #f8f7f7;
  *-webkit-transform: skew(0deg, 1deg);
  *        transform: skew(0deg, 1deg);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3), 0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px -2px rgba(0, 0, 0, 0.2); }

.event-input {
  border: 1px solid #ced4da;
  padding: 10px 12px;
  font-size: 16px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #fff; }

.param-line {
  height: 50px; }

.param-line-delete {
  cursor: pointer;
  color: #e84249;
  position: relative;
  top: 38px; }

.webhook-add-button {
  width: 613px;
  background-color: #fefefe;
  color: #e84249;
  border: 1px solid #e84249;
  border-radius: 4px;
  height: 40px;
  text-align: center;
  vertical-align: center; }

.webhook-add-button span {
  font-family: 'FuturaBold';
  font-size: 15px;
  margin-left: 20px;
  text-transform: capitalize; }

.webhook-add-container {
  margin-top: 32px;
  margin-left: 8px; }

.event-container {
  background-color: #fff;
  padding: 5px 5px 20px 20px;
  border-radius: 4px;
  color: #292929;
  width: 100%; }
  .event-container .checked-icon {
    margin-top: 40px;
    color: #7cc4ac; }

.input-error-label-fullwidth {
  width: 400px; }

.webhook-action {
  text-align: right;
  text-transform: 'none'; }

.webhook-action button {
  margin-left: 30px; }

.webhook-section {
  margin-bottom: 40px; }

.webhook-section-title {
  color: #262957;
  font-size: 20px; }

.webhook-param {
  display: flex;
  justify-content: space-between; }

.webhook-param p {
  color: #262957;
  font-style: italic;
  font-size: 15px;
  text-align: left;
  margin-top: 0; }

.webhook-delete {
  font-weight: bold;
  float: right; }

.webhook-param-title p {
  font-style: normal;
  opacity: 0.8; }

.webhook-param-description {
  color: #262957;
  font-size: 18px;
  text-align: left; }

.webhook-header-body {
  width: 60%; }

.webhook-params {
  width: 40%; }

.webhook-input-method {
  border: 1px solid #ced4da;
  padding: 3px 15px;
  font-size: 16px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #fff;
  border-bottom: 0; }

.webhook-input-method::before {
  content: ''; }

.collapse-container {
  background: #f6f6f6;
  border-radius: 10px; }
  .collapse-container div div {
    display: flex;
    font-family: 'FuturaBook';
    font-size: 16px;
    color: #262957; }
    .collapse-container div div .test-content {
      padding: 10px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid; }
      .collapse-container div div .test-content p {
        margin: 0;
        text-align: justify; }
      .collapse-container div div .test-content button {
        width: 25%;
        margin: 15px auto 0px auto;
        color: #e84249;
        border: 1px solid rgba(232, 66, 73, 0.5); }
        .collapse-container div div .test-content button:hover {
          border: 1px solid #e84249;
          background-color: rgba(232, 66, 73, 0.08); }
        .collapse-container div div .test-content button span {
          font-family: 'FuturaBold';
          font-size: 15px; }
    .collapse-container div div .test-result-content {
      padding: 10px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .collapse-container div div .test-result-content > div {
        width: 100%; }
      .collapse-container div div .test-result-content .button-bold {
        margin-right: 8px; }

#popup-event-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  *background-color: rgba(0, 0, 0, 0.5); }

#popup-event-container {
  position: fixed;
  width: 1200px;
  height: 600px;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  /* Negative half of height. */
  margin-left: -600px;
  padding: 0; }

#popup-event {
  background-color: #fefefe;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  margin-top: 0; }

#popup-event-title {
  font-size: 25px;
  color: #262957;
  font-family: 'FuturaBold';
  position: relative;
  top: -10px; }

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e84249;
  font-family: 'FuturaBook';
  height: 500px;
  margin-top: 10%; }
  .notFoundContainer div {
    display: flex;
    margin-bottom: 50px; }
  .notFoundContainer span.number {
    font-size: 200px; }

.custom-bubble-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute; }

.custom-bubble-container img {
  position: absolute;
  width: 70px;
  height: 70px; }

#meteor1 {
  top: -10%;
  left: 10%;
  width: 120px;
  height: 120px;
  -webkit-animation-name: meteor1;
          animation-name: meteor1;
  -webkit-animation-duration: 1800ms;
          animation-duration: 1800ms;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
          animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92); }

#meteor2 {
  top: 0%;
  left: 10%;
  -webkit-animation-name: meteor;
          animation-name: meteor;
  -webkit-animation-duration: 1200ms;
          animation-duration: 1200ms;
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms; }

#meteor3 {
  top: -15%;
  left: 5%;
  -webkit-animation-name: meteor;
          animation-name: meteor;
  -webkit-animation-duration: 1200ms;
          animation-duration: 1200ms;
  -webkit-animation-delay: 1400ms;
          animation-delay: 1400ms; }

#meteor4 {
  top: -10%;
  left: 10%;
  width: 70px;
  height: 70px;
  -webkit-animation-name: meteor;
          animation-name: meteor;
  -webkit-animation-duration: 1200ms;
          animation-duration: 1200ms;
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms; }

#meteor5 {
  top: -10%;
  left: 0%;
  -webkit-animation-name: meteor;
          animation-name: meteor;
  -webkit-animation-duration: 1200ms;
          animation-duration: 1200ms;
  -webkit-animation-delay: 900ms;
          animation-delay: 900ms; }

/*** KEYFRAMES ***/
@-webkit-keyframes meteor1 {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(3000%) translateX(3000%);
            transform: translateY(3000%) translateX(3000%); } }
@keyframes meteor1 {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(3000%) translateX(3000%);
            transform: translateY(3000%) translateX(3000%); } }

@-webkit-keyframes meteor {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(2500%) translateX(2500%);
            transform: translateY(2500%) translateX(2500%); } }

@keyframes meteor {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(2500%) translateX(2500%);
            transform: translateY(2500%) translateX(2500%); } }

.backgroundCircle-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.backgroundCircle-container svg {
  width: 100%;
  height: 100%; }

#oval1,
#oval4,
#oval6 {
  -webkit-animation-name: i;
          animation-name: i;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
          animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

#oval2,
#oval5 {
  -webkit-animation-name: j;
          animation-name: j;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
          animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

#oval3 {
  -webkit-animation-name: h;
          animation-name: h;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
          animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

#oval7 {
  -webkit-animation-name: k;
          animation-name: k;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
          animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

/*** KEYFRAMES ***/
@-webkit-keyframes i {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px) translateX(15px);
            transform: rotateX(0deg) translateY(15px) translateX(15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }
@keyframes i {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px) translateX(15px);
            transform: rotateX(0deg) translateY(15px) translateX(15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@-webkit-keyframes j {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
            transform: rotateX(0deg) translateY(15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@keyframes j {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
            transform: rotateX(0deg) translateY(15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@-webkit-keyframes h {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px) translateX(-15px);
            transform: rotateX(0deg) translateY(15px) translateX(-15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@keyframes h {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px) translateX(-15px);
            transform: rotateX(0deg) translateY(15px) translateX(-15px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@-webkit-keyframes k {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  15% {
    -webkit-transform: rotateX(0deg) translateY(-30px) translateX(-30px);
            transform: rotateX(0deg) translateY(-30px) translateX(-30px); }
  30% {
    -webkit-transform: rotateX(0deg) translateY(-60px) translateX(0);
            transform: rotateX(0deg) translateY(-60px) translateX(0); }
  45% {
    -webkit-transform: rotateX(0deg) translateY(-30px) translateX(30px);
            transform: rotateX(0deg) translateY(-30px) translateX(30px); }
  60% {
    -webkit-transform: rotateX(0deg) translateY(-90px) translateX(60px);
            transform: rotateX(0deg) translateY(-90px) translateX(60px); }
  75% {
    -webkit-transform: rotateX(0deg) translateY(0) translateX(90px);
            transform: rotateX(0deg) translateY(0) translateX(90px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@keyframes k {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); }
  15% {
    -webkit-transform: rotateX(0deg) translateY(-30px) translateX(-30px);
            transform: rotateX(0deg) translateY(-30px) translateX(-30px); }
  30% {
    -webkit-transform: rotateX(0deg) translateY(-60px) translateX(0);
            transform: rotateX(0deg) translateY(-60px) translateX(0); }
  45% {
    -webkit-transform: rotateX(0deg) translateY(-30px) translateX(30px);
            transform: rotateX(0deg) translateY(-30px) translateX(30px); }
  60% {
    -webkit-transform: rotateX(0deg) translateY(-90px) translateX(60px);
            transform: rotateX(0deg) translateY(-90px) translateX(60px); }
  75% {
    -webkit-transform: rotateX(0deg) translateY(0) translateX(90px);
            transform: rotateX(0deg) translateY(0) translateX(90px); }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0);
            transform: rotateX(0deg) translateY(0); } }

@charset "UTF-8";
.loader-container-med {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  z-index: 5000;
  display: inline-block;
  text-align: center; }

.loader-text-med {
  position: absolute;
  /* postulat de départ */
  top: calc(50% + 100px);
  left: 50%;
  /* à 50%/50% du parent référent */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 100px;
  color: white;
  font-family: 'FuturaHeavy';
  font-size: 32px; }

.loader-container-med-hidden {
  display: none; }

.pulse-med {
  width: 95px;
  height: 95px;
  border-radius: 1%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(181, 46, 44, 0.8);
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.pulse-med img {
  margin-left: -27px;
  margin-top: -27px; }

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0.8); }
  70% {
    box-shadow: 0 0 0 20px rgba(232, 66, 73, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0.8); }
  70% {
    box-shadow: 0 0 0 20px rgba(232, 66, 73, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(232, 66, 73, 0); } }

.laddition-loader-container-med {
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
  background-image: linear-gradient(141deg, #1f1919 0, #363636 71%, #463f3f 100%);
  z-index: 999999999999999; }

.laddition-loader-container-med__hidden .pulse-med {
  -webkit-animation: none;
          animation: none; }

.variable-modal {
  display: flex;
  align-items: center;
  justify-content: center; }

.variable-modal-container {
  background: white;
  padding: 20px;
  text-align: center;
  outline: none; }

.export-item {
  display: flex;
  align-items: center; }

#modal-title {
  color: #262957; }

.variable-modal {
  display: flex;
  align-items: center;
  justify-content: center; }

.variable-modal-container {
  background: white;
  padding: 20px;
  text-align: center;
  outline: none; }

.error-boundary {
  width: 70%;
  margin: 100px auto 20px auto;
  text-align: center; }
  .error-boundary img {
    width: 200px; }
  .error-boundary h4 {
    color: #4e708d; }
  .error-boundary p {
    color: #4e708d;
    font-size: 15px; }

